#shop-now{
    background-color: white;
}
.shop-container{
    padding: 3rem 2rem;
}
.shop-title{
    color: #0F2033;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.shop-all-liveup{
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
    font-weight: 400;
    background-color:#9FC5ED ;
    padding: .2rem 1.1rem;
    border-radius: 15px;
}
.shop-all-liveup:hover{
    text-decoration: none;
    color: white;
    background-color: #798898;
}

.shop-bottle{
    display: flex;
    justify-content: space-evenly;   
}
.bottle-color{
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.bottle-color >img{
    height: 400px;
    width: 300px;
    border-radius: 10px;
    
}
.bottle-color>a{
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.2rem;
    text-decoration: none;
    color: black;
}

@media screen and (max-width:1024px){
    
}

@media screen and (max-width:980px){
    .bottle-color >img{
        height: 350px;
        width: 250px;
    }
}
@media screen and (max-width:823px){
    .bottle-color >img{
        height: 300px;
        width: 200px;
    }
}
@media screen and (max-width:768px){
    .bottle-color >img{
        height: 250px;
        width: 150px;
    }
    .bottle-color>a{
        font-size: 1.4rem;
    }
}

@media screen and (max-width:550px){
    .shop-bottle{
        flex-direction: column;
    }
    .bottle-color >img{
        margin-left: auto;
        margin-right: auto;
    }
    .bottle-color{
        margin-bottom: 1.4rem;
    }
}
