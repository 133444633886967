#gallery{
    padding: 4rem 3rem;
     background-color: #9FC5ED;
}

.cards{
    display: flex;
    padding: 2rem 1rem;
    justify-content: space-around;
}
.display-cards{
    height: 500px;
    width: 350px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.1);
    transition: 0.4s ease-out;
}
/*
.display-cards:hover{
    transform: scale(1.2);
} */
.images:hover{
    transform: scale(1.1);
} 

.images{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    transition: all .2s ease-in-out;
}
.gallery-button{
    color: black;
    text-decoration: none;
    position: absolute;
    bottom: 2%;
    left: 3%;
    padding: .6rem 1.7rem;
    border-radius: 15px;
    background-color: white;
    font-weight: bolder;
    font-size: 1rem;
    border: 2px solid black;
}
.gallery-button:hover{
    background-color: transparent;
    text-decoration: none;
    border: 2px solid white;
    color: white;
}
.gallery-button>a{
    text-decoration: none;
    color: black;
    border: none;
}

.gallery-button:hover a{
    background-color: transparent;
    color: white;
}

@media screen and (max-width:1024px){
    .display-cards{
        height: 450px;
        width: 250px;
    }
}

@media screen and (max-width:902px){
    .display-cards{
        height: 450px;
        width: 200px;
    }
}

@media screen and (max-width:768px){
    .cards{
        flex-wrap: wrap;
    }
    .display-cards:nth-child(-n+2){
        flex: 40%;
        margin-left: .5rem;
    }
    .display-cards:nth-child(3){
        width: 300px;
        margin-top: 1rem;
    }
}

@media screen and (max-width:450px){
    .cards{
        flex-direction: column;
    }
    .display-cards{
        flex: none !important;
        margin-right: auto !important;
        margin-left: auto !important;
        height: 300px !important;
        width: 200px !important;
        margin-top: 1rem;
    }
    .gallery-button{
        padding: .3rem 1rem;
        font-size: .9rem;
    }
   
}