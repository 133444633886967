#footer{
    background-color: #0F2033;
    padding: 2rem;
    padding-bottom: 0;
    color: white;
}
.footer-container{
    display: grid;
    grid-template-columns: 1.2fr 1fr .8fr;
    padding-bottom: 2rem;
    justify-content: space-evenly;
    align-items: start;
}
.footer-company>p{
    
    font-size: 2.5rem;
    font-weight: bold;
}

.social-media{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.social-media>a{
    text-decoration: none;
    color: white;
}
.fa-instagram,.fa-tiktok{
    font-size: 1.8rem;
    padding: 1rem;

}
.fa-instagram:hover,.fa-tiktok:hover {
    transform: scale(1.1);
    color: rgb(240, 54, 110);
}
.fa-square-facebook:hover{
    transform: scale(1.1);
    color: rgb(54, 85, 240);
}
@media screen and (max-width:450px){
   .footer-container{
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
   }
   .social-media{
    flex-direction: row;

}
}
