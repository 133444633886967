.home{
    height: 80vh;
    padding-top: 0;
}
.home-container{
    background-image: url('../../assets/home-img.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: inherit;
    max-width: 100%;
}
.home-layout{
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2rem;
    padding-top: 4rem;
    padding-bottom: 2%;
}
.home-text-box{
   
    text-align: center;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    backdrop-filter: blur(5px);
    border-radius: 1rem;
}
.home-text{
    color: #494644d9;
}
.liveup{
    font-size: 2.5rem;
    
}
.tagline{
    font-size: 1.5rem;
}


@media screen and (max-width:425px){
    .home{
        height: 50vh;
    }
    .tagline{
        font-size: 1.2rem;
    }
    .home-text-box{
        width: 60%;
        margin-bottom: 4%;
    }
}