@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root{
  --header-height:3rem;
  --hue:0;
  --sat:0%;
  --title-color:#000000;
  --title-color-dark:hsl(var(--hue),var(--sat),0%);
  --text-color:hsl(var(--hue),var(--sat),46%);
  --body-color:#EEF5FF;
  --container-color:#f8f7f4;

  --body-font:"Poppins",sans-serif;
  --big-font-size:3.5rem;
  --h1-font-size:2.25rem;
  --h2-font-size:1.5rem;
  --h3-font-size:1.25rem;
  --normal-font-size:1rem;
  --small-font-size:0.875rem;
  --smaller-font-size:0.81rem;
  --tiny-font-size:0.625rem;

  --font-normal:400;
  --font-medium:500;
  --font-semi-bold:600;

  --mb-0-25:0.25rem;
  --mb-0-5:0.5rem;
  --mb-0-75:0.75rem;
  --mb-1:1rem;
  --mb-1-5:1.5rem;
  --mb-2:2rem;
  --mb-2-25:2.25rem;
  --mb-3:3rem;

  --z-tooltip: 10;
  --z-fixed:100;
  --z-modal:1000;
}
  @media  screen and (max-width: 992px) {
    :root{
      --big-font-size:2.75rem;
      --h1-font-size:1.5rem;
      --h2-font-size:1.25rem;
      --h3-font-size:1rem;
      --normal-font-size:0.9rem;
      --small-font-size:0.8rem;
      --smaller-font-size:0.75rem;
    }
  }

*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;

}

body,
button,
textarea,
input{
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body{
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3{
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}

.section-title{
  font-size: var(--h1-font-size);
   color: var(--title-color);
}
.section-subtitle{
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section-subtitle,
.section-title{
text-align: center;
}

.container{
  max-width: 986px;
  margin-left: auto;
  margin-right: auto;
}

.grid{
  display: grid;
  gap: 1.5rem;
}

.button{
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.button:hover{
  background-color: var(--title-color-dark);
}
.button-icn{
  margin-left: var(--mb-0-5);
}
.button-flex{
  display: inline-flex;
  align-items: center;
}


@media screen and (max-width:992px){
  .container{
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
  .button{
    padding:  1rem 1.75rem;
  }
  .button_icn{
    width: 22px;
    height: 22px ;
  }
}
@media screen and (max-width:768px){
  body{
    margin: 0 0 var(--header-height) 0;
  }
  .section{
    padding: 2rem 0 4rem;
  }
  .section_subtitle{
    margin-bottom: var(--mb-3);
  }
}
@media screen and (max-width:576px){
  
}
@media screen and (max-width:350px){
  :root{
    --big-font-size:2rem
  }
  .container{
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}